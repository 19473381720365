import React, { Component } from "react"

export default class NotFound extends Component {
    render() {
        return(
            <div className="inset">
                <p>Page not found.</p>
            </div>
        )
    }
}
